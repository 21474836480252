import $ from 'jquery';
import Rellax from 'rellax';
import {TweenMax, TimelineMax} from "gsap";
import 'foundation-sites';
import ColorThief from './color-thief.mjs';
import Swiper from 'swiper';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

class App {

    run() {
        this.apply();
        this.modalPDP();
        this.configureList();
        this.cookies();
    }

    apply() {
        $(document).foundation();

        function inViewport() {
            $.fn.isInViewport = function () {

                var elementTop = $(this).offset().top;

                var elementBottom = elementTop + $(this).outerHeight() / 2;

                var viewportTop = $(window).scrollTop();

                var viewportHalf = viewportTop + $(window).height() / 2;

                var viewportBottom = viewportTop + $(window).height();

                return viewportBottom > elementBottom;

            };
        }

        inViewport();
        // var rellax = new Rellax('.rellax', {
        //     wrapper: '.photo-body'
        // });

        $(window).on("load", function() {
            setTimeout(function() {
           $(".popup-event").addClass('active');
            }, 300)
           
        })

        if($('.trigger-parallax').length > 0 === true) {
            // Init ScrollMagic Controller
        var controller = new ScrollMagic.Controller({
            // globalSceneOptions: {
            //     triggerHook: 'onLeave'
            // }
        });

        // Create Animation for 0.5s
        var tl = new TimelineLite()

          var tween = tl.to('.parallax-01', 0.5, {
            y: 80,
          }, "end")
          .to('.parallax-02', 0.5, {
            y: -100,
          }, "end")
          .to('.parallax-03', 0.5, {
            y: 200,
          }, "end")
          .to('.parallax-04', 0.5, {
            y: 100,
          }, "end")
          .to('.parallax-05', 0.5, {
            y: -150,
          }, "end");

        // Create the Scene and trigger when visible
          var scene = new ScrollMagic.Scene({
            triggerElement: '.trigger-parallax',
            duration: '130%',
            // offset: 150 /* offset the trigger 150px below #scene's top */
          })
          .setTween(tween)
          .addTo(controller);
          
          // Add debug indicators fixed on right side
           // scene.addIndicators();
        }
        


        $('.product-container__name').each(function () {
            $(this).wrap("<div class='container-overflow'></div>");
        });

        $(".modal-menu__list a").each(function() {
            $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
        });

        $("#menu").on("click", function () {
            $(".modal-menu").addClass("opened");
            var tl = new TimelineLite();
            tl.staggerTo(".modal-menu__list li a", 1, {
                y: 0,
                rotation: 0,
                ease: Power3.easeOut,
            }, 0.1);
        });
        $(".modal-menu__close").on("click", function () {
            setTimeout(function() {
                $(".modal-menu").removeClass("opened");
            },200)
            TweenLite.to(".modal-menu__list li a", 0.3, {
              y: "80px",
              rotation: 6,
            })
        });


        $(".modal-menu__list a").hover(
            function() {
                let elements = $(this).find(".letter");
                // hover
                var tl = new TimelineLite();
                    tl.staggerTo(elements, 1, {
                        color: "white",
                        ease: Power3.easeOut,
                    }, 0.05);
                TweenLite.to(this, 0.5, {
                  scale: "1.1",
                })

              }, function() {
                let elements = $(this).find(".letter");
                // hover
                var tl = new TimelineLite();
                    tl.staggerTo(elements, 1, {
                        color: "transparent",
                        ease: Power3.easeOut,
                    }, 0.05);

                TweenLite.to(this, 0.5, {
                  scale: "1",
                })
              }
        );

        $(window).on('load resize scroll', function () {
            $('.header-body__title').each(function () {

                if ($(this).isInViewport()) {
                    $(this).addClass('inview');

                    const toright = $(this).find(".h1 span>div");
                    TweenLite.to(toright, 1, {x: 0, ease: Power2.easeOut});
                }

            });
            $('.concept-body__title').each(function () {

                if ($(this).isInViewport()) {
                    $(this).addClass('inview');

                    const toright = $(this).find(".h1 span>div");
                    TweenLite.to(toright, 1, {x: 0, ease: Power2.easeOut});
                }

            });
        });

        $(window).on("load resize", function () {

            let widthCol = $(".background-grid .column:first-child").width() + 1;
            let widthCol2 = widthCol * 2;
            let widthCol3 = widthCol * 3;
            let widthFull = $(window).width() - widthCol;

            if (Foundation.MediaQuery.atLeast('large')) {
                // True if medium or large
                // False if small


                if ($(".main").children().hasClass("configurator") === false) {

                    $(".main").css("padding-left", widthCol);
                    $("#menu").width(widthCol);
                    $(".menu-left").width(widthCol - 59);
                    $(".footer").css("left", widthCol);
                    $(".footer").width(widthFull - 80);
                    $(".main, .popup-modal").width(widthFull);
                    $(".header-body, .concept-body").width(widthFull);
                    $(".header-body__image").width(widthCol3 - 30);
                    $(".concept-body__video video").css("right", widthCol);
                    $(".header-body__title").css("left", widthCol);
                    $(".concept-body__title").css("left", widthCol);
                    $(".header-body__title .h1 span, .concept-body__title .h1 span").width(widthCol);
                    $("#configure").width(widthCol);
                    $(".hide-shop").width(widthCol);
                    $(".pdp-buttons a").width(widthCol - 36);
                    $(".pdp-buttons").css("right", widthCol);
                    $(".menu-top__logo").addClass("hide-for-large");

                } else {

                    // $(".main").css("padding-right", widthCol2);
                    $("#menu").width(widthCol);
                    $(".menu-left").hide();
                    // $(".footer").css("left", widthCol);
                    // $(".footer").width(widthFull - 100);
                    // $(".main").width(widthFull);
                    $(".header-body, .concept-body").width(widthFull);
                    $(".header-body__image").width(widthCol3 - 30);
                    $(".concept-body__video video").css("right", widthCol);
                    $(".header-body__title").css("left", widthCol);
                    $(".concept-body__title").css("left", widthCol);
                    $(".header-body__title .h1 span, .concept-body__title .h1 span").width(widthCol);
                    $("#configure").width(widthCol);

                }
            }

            if (Foundation.MediaQuery.is('medium only') || Foundation.MediaQuery.is('small only')) {
                // True if small
                // False if medium or large

                $(".main").css("padding-left", "0");
                $(".header-body__title").css("left", "0");
                $(".concept-body__title").css("left", "0");
                $(".header-body__title .h1 span, .concept-body__title .h1 span").width(widthCol);
                $(".header-body__title .h3").css("padding-left", widthCol);
            }


            // $(".photo-body").css("padding-left", widthCol);
        })


        $(document).ready(function () {
            setTimeout(function(){
                $(".product-container__image").each(function () {
                    var $myImage = $(this);
                    var $container = $(this).closest(".product-container");

                    var colorThief = new ColorThief();

                    //Grabs 8 swatch color palette from image and sets quality to 5 (0 =slow, 10=default/fast)
                    var cp = colorThief.getPalette($myImage[0], 8, 5);

                    //Sets background to 1rd color in the palette.
                    $container.css('background-color', 'rgb(' + cp[0][0] + ',' + cp[0][1] + ',' + cp[0][2] + ')');

                })
                $(".photo-body__block__container>img").each(function () {
                    var $myImage = $(this);
                    var $container = $(this).parent();

                    var colorThief = new ColorThief();

                    //Grabs 8 swatch color palette from image and sets quality to 5 (0 =slow, 10=default/fast)
                    var cp = colorThief.getPalette($myImage[0], 8, 5);

                    //Sets background to 1rd color in the palette.
                    $container.css('background-color', 'rgb(' + cp[0][0] + ',' + cp[0][1] + ',' + cp[0][2] + ')');

                })
                $(".image-with-shadow>img").each(function () {
                    var $myImage = $(this);
                    var $container = $(this).closest(".image-with-shadow");

                    var colorThief = new ColorThief();

                    //Grabs 8 swatch color palette from image and sets quality to 5 (0 =slow, 10=default/fast)
                    var cp = colorThief.getPalette($myImage[0], 8, 5);

                    //Sets background to 1rd color in the palette.
                    $container.css('background-color', 'rgb(' + cp[1][0] + ',' + cp[1][1] + ',' + cp[1][2] + ')');

                })
            },300);
                
        });

        // setTimeout(function () {
        //     var swiper = new Swiper('.pdp-slide-container', {
        //         pagination: {
        //             el: '.pdp-slide-pagination',
        //             type: 'bullets',
        //             clickable: true,
        //         },
        //     });
        // }, 200)

        $(window).on("resize load", function () {
            if (Foundation.MediaQuery.is('medium only') || Foundation.MediaQuery.is('small only')) {
                if ($(".main>div").hasClass("pdp") === true) {
                    const title = $(".info-product>.h1");
                    const structure = title.html();
                    title.remove();
                    $(".append-mobile").append(structure).wrap("<h1 class='h1 uppercase bold'></h1>");

                    // Also can pass in optional settings block
                    // const rellax = new Rellax('.append-mobile', {
                    //     speed: -3,
                    //     center: false,
                    //     wrapper: null,
                    //     round: true,
                    //     vertical: true,
                    //     horizontal: false
                    // });
                }
            }
        })

        if ($("#wpcf7-f32-o1").length > 0 === true) {
            $(".wpcf7-form-control-wrap").each(function() {
                var input = $(this).html();
                $(this).parent().append(input);
                $(this).parent().children("br").detach();
                $(this).detach();
            });
        }
    }

    modalPDP () {
        $(".pdp-show-more").on("click", function() {
            const $this = $(this);
            const $modal = $(".modal-container");
            const $modalbody = $(".modal-container__body");
            const $plus = $(this).children();

            $this.addClass("active");
            $modal.addClass("active");


            var tl = new TimelineLite();
            tl.to($this, 1.5, {
                scale: 150,
                ease: Power3.easeOut,
            }, "start");
            tl.to($modalbody, 0.8, {
                left: 0,
                opacity: 1,
                ease: Power3.easeOut,
            }, 0.5);

        });

        $(".modal-container__close").on("click", function() {
            const $this = $(".pdp-show-more");
            const $modal = $(".modal-container");
            const $modalbody = $(".modal-container__body");

            setTimeout(function() {
                $this.removeClass("active");
                $modal.removeClass("active");
            },800)

            var tl = new TimelineLite();
            tl.to($modalbody, 0.4, {
                left: "60px",
                opacity: 0,
                ease: Power3.easeOut,
            }, "start");
            tl.to($this, 0.8, {
                scale: 1,
                ease: Power3.easeOut,
            }, 0.2);
            

        });

        $(".popup-event").on("click", function() {
            $(".popup-modal").addClass('active');
        })
        $(".close-popup-modal").on("click", function() {
            $(".popup-modal").removeClass('active');
        })
    }


    configureList () {
        $(".right-options .list-configurable__item").each(function() {
            $(this).on("click", function() {
                $(".right-options .list-configurable__item").removeClass("active");
                $(".left-viewer__image").removeClass("active");

                const product = $(this).attr("data-product-item");
                const link = $(this).attr("link-item");

                $(this).addClass("active");
                $(this).closest(".right-options").children("#next-configurator").attr("href", link);
                $(this).closest(".configure-container").children().children(".left-viewer__image[data-product=" + product + "]").addClass("active");
            });
        });
        $(".right-options .list-configurable__item:first-child").click();

        if ($('.woocommerce-thankyou-order-received').length > 0 === true) {
            $('h1').detach();
            $('.woocommerce-thankyou-order-received').removeClass("h2");
            $('.woocommerce-thankyou-order-received').addClass("h1");
            $('.woocommerce-thankyou-order-received').addClass("bold");
        }
    }


    cookies () {
        window.cookieconsent.initialise({
          "palette": {
            "popup": {
              "background": "#000000",
              "text": "#ffffff"
            },
            "button": {
              "background": "transparent",
              "border": "#ffffff",
              "text": "#ffffff"
            }
          },
          "content": {
            "message": "\nQuesto sito Web utilizza i cookie per assicurarti la migliore esperienza sul nostro sito Web.",
            "dismiss": "Accetto",
            "link": "Scopri di più",
            "href": "https://www.choose.fashion/privacy-policy/"
          }
        });

        $(".cc-window").each(function() {
            const dismiss = $(this).children(".cc-compliance").html();
            $(this).prepend(dismiss);
            $(this).children(".cc-dismiss").text("×").addClass("close-cookie").wrap("<div class='cc-compliance'></div>");
        });
    }

}

const app = new App();
export default app;
