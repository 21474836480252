import $ from 'jquery';
import Drift from 'drift-zoom';

class Zoom{
	run() {
		this.pdpZoom();
	}

	pdpZoom() {
		if ($(".pdp").length > 0 === true) {
			if($(window).width() > 1024 === true) {
				setTimeout(function() {
					new Drift(document.querySelector(".pdp-slide-container img"), {
					  paneContainer: document.querySelector(".zoom-container"),
					  zoomFactor: 2,
					});
				},500);
			}
		}
		
		// $(".pdp-slide-container img").hover(function() {
		// 	$(".zoom-container").css("opacity", "1");
		// },
		// function() {
		// 	$(".zoom-container").css("opacity", "0");
		// });
	}
}

const zoomjs = new Zoom();
export default zoomjs;