import $ from 'jquery';
import LazyLoad from "vanilla-lazyload";
import App from './modules/app';
import Zoom from './modules/zoom';
import { CookieConsent } from 'cookieconsent';

window.addEventListener('DOMContentLoaded', function(){
	var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // ... more custom settings?
    });
    App.run();
    Zoom.run();
    
    $(window).on("load", function() {
	    setTimeout(function() {
	    	$(".preload").fadeOut(300);
	    }, 300)
        setTimeout(function() {
            $(".photo-body").css("margin", "0 auto");
        }, 100)
	});
 });
